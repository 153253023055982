<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/fixledger`"
                title="Fixed Ledger"
                :columns="columns"
                :formOptions="formOptions"
                routerpath="/setup/addfixedledger"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'
import moment from 'moment'
import axios from '@/components/axios'
const baseApi = process.env.VUE_APP_APIENDPOINT

export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'ID',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search ID'
          },
          hidden: true
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Code',
          field: 'code',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Code'
          }
        },
        {
          label: 'Ledger Name',
          field: 'ledgerid',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Ledger Name'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ],
      formOptions: {
        gridForm: true,
        formTitle: `${
          this.$route.params.id ? 'Edit Fixed Ledger' : 'Add Fixed Ledger'
        }`,
        submitRouterPath: '/setup/fixed-ledger',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/fixledger`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Name',
            name: 'name',
            value: '',
            type: 'text',
            placeholder: 'Enter Name',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Code',
            name: 'code',
            value: '',
            type: 'text',
            placeholder: 'Enter Code',
            class: 'col-md-12',
            required: true
          },
          {
            label: 'Ledger',
            name: 'ledgerid',
            value: '',
            type: 'dropdown',
            class: 'col-md-12',
            url: 'ledger',
            required: true,
            // inputStyle: "height: 25px; padding: 5px",
            options: []
          }
        ]
      }
    }
  },
  mounted () {
    const role = JSON.parse(localStorage.getItem('userData')).role
    if (role !== 'admin') {
      this.$router.push('/')
    }
  },
  beforeMount () {
    this.getDropdowns()
  },
  methods: {
    getDropdowns () {
      const accessToken = localStorage.getItem('accessToken')
      const baseApi = process.env.VUE_APP_APIENDPOINT
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == 'dropdown' && item.url) {
          const requestOptionsUsers = {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            },
            url: `${baseApi}/${item.url}`
          }

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data)
            })
            .catch((error) => console.log(error, 'error'))
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
